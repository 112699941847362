import React from "react";
import { BaseLayout } from "../components/Layout";
import { graphql } from "gatsby";
import { TeamSection } from "../components/Sections";

const Team = ({ data }) => {
  const teamInfo = data.allTeamInfoJson.edges;

  return (
    <BaseLayout page="team" leftBox="contact" rightBox="faq">
      <TeamSection teamInfo={teamInfo} />
    </BaseLayout>
  );
};

export default Team;

export const query = graphql`
  {
    allTeamInfoJson {
      edges {
        node {
          title
          name
          introduction
          team_image {
            childrenImageSharp {
              gatsbyImageData(
                quality: 95
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                height: 770
                width: 500
              )
            }
          }
        }
      }
    }
  }
`;
